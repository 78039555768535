import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

function Pagination({ page, last_page, collectData, searchParams = null }) {

    const pages = [];
    for (let i = 1; i <= last_page; i++) {
        pages.push(i);
    }

    const handlePageChange = (newPage) => {
        if (searchParams !== null) {
            let data = {
                ...searchParams,
                page: newPage
            }
            collectData(data)

        } else {
            collectData(newPage)
        }
    }

    return (
        <nav>
            <ul className="pagination gap-2 flex-wrap">
                <li className="page-item">
                    <div className={`page-link d-flex align-items-center justify-content-center h-100 rounded-1 ${page === 1 ? 'text-info' : ''}`} style={{ cursor: 'pointer' }} onClick={page === 1 ? () => { } : () => handlePageChange(page - 1)}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                </li>
                {pages.map((pageNumber) => (
                    <li key={pageNumber} className={`page-item${pageNumber === page ? ' active' : ''}`}>
                        <div className="page-link rounded-1" style={{ cursor: 'pointer' }} onClick={pageNumber === page ? () => { } : () => handlePageChange(pageNumber)}>{pageNumber}</div>
                    </li>
                ))}
                <li className="page-item">
                    <div className={`page-link d-flex align-items-center justify-content-center h-100 rounded-1 ${page === last_page ? 'text-info' : ''}`} style={{ cursor: 'pointer' }} onClick={last_page === page ? () => { } : () => handlePageChange(page + 1)}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination